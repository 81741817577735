:root {
  --primarycolor: #5CDB95;
  --primarycolor-text: #05386B;

  --secondarycolor: #05386B;
  --secondarycolor-text: #FFFFFF;

  --color3: #8EE4AF;
  --color3-text: #000000;

  --color4: #379683;
  --color4-text: #FFFFFF;

  --color5: #EDF5E1;
  --color5-text: #000000;

  --herocolor: var(--primarycolor);
  --herocolor-text: var(--secondarycolor);

  --ctabutton: var(--secondarycolor);
  --ctabutton-text: var(--secondarycolor-text);
  --themecolorrgba: 61, 128, 228;
}


html, body { height: 100%; }
body { margin: 0;     font-family: Poppins,Helvetica,sans-serif; }
a{
  color: var(--secondarycolor);
  cursor: pointer;
  text-decoration: underline;
}
a:hover
{
  color: var(--primarycolor);
}


